import api from './Api';

export const getMenu = (language) => {
  return api.get('v1/categories/tree/'+language);
};

export const getProducts = ( params ) => {
  return api.post('v1/products/filter',params
    );
};
export const getProduct = (id,language) => {
  return api.get('v1/products/'+id+'/'+language);
};
export const getCollections = (params) => {
  return api.get('v1/collection/'+params); 
}

export const getDeals = () => {
  return api.get('v1/deals');
};

export const userAddress = ( params ) => {    
  return api.post('v1/address ',params);
};
export const getBanners = ()  => {    
  return api.get('banners');
};

export const updateCart = ( params ) => {    
  return api.post('v1/cart ',params);
};
export const getCart = (selectedLanguage,user)  => {    
  return api.get('v1/cart/'+user+'/'+selectedLanguage);
};