import React, { useEffect, useState, useRef, useCallback } from 'react';
import './ProductsSection.css';
import ProductCard from '../widget/ProductCard';
import '../Flash/FlashSales.css';
import { getProducts } from '../../util/ApiService';
import { useNavigate } from 'react-router-dom';

const ProductsSection = ({ subcategories }) => {
    const [products, setProducts] = useState([]);
    const [offset, setOffset] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [priceRange, setPriceRange] = useState([0, 500]);
    const observer = useRef(null);
    const navigate = useNavigate();
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');

      useEffect(() => {
        const handleLanguageChange = () => {
          const newLanguage = localStorage.getItem('selectedLanguage');
          setSelectedLanguage(newLanguage);
        };
    
        window.addEventListener('languageChanged', handleLanguageChange);
        return () => window.removeEventListener('languageChanged', handleLanguageChange);
      }, []);
    
    // API call to fetch products
    const fetchProducts = useCallback(() => {
        if (isFetching || !hasMore) return;
        setIsFetching(true);

        const params = {
            offset: offset,
            pagesize: 20, 
            languageCode: selectedLanguage
        };

        getProducts(params)
            .then(response => {
                const newProducts = response.data.content;
                if (newProducts.length === 0) {
                    setHasMore(false);
                } else {
                    setProducts(prev => [...prev, ...newProducts]);
                    setOffset(prev => prev + 20); // Increase offset for the next call
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => setIsFetching(false));
    }, [offset, isFetching, hasMore,selectedLanguage]);

    // Trigger fetchProducts on mount
    useEffect(() => {
        fetchProducts();
    }, []);

    // Intersection Observer for Infinite Scroll
    useEffect(() => {
        if (!hasMore) return;

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                fetchProducts();
            }
        });

        const lastProduct = document.querySelector('.product-card:last-child');
        if (lastProduct) observer.current.observe(lastProduct);

        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [products, fetchProducts]);
    const handlePriceChange = (event) => {
        setPriceRange(event.target.value.split(',').map(Number));
    };
    const handleCardClick = (id) => {
        navigate(`/product/${id}`);
      };

    return (
        <div className="products-section">
            {/* Left-side Filters */}
            <div className="filters-container">
                <h3>Filters</h3>

                {/* Gender Filter */}
                <div className="filter-group">
                    <h4>Gender</h4>
                    <ul>
                        <li><input type="checkbox" id="male" /> <label htmlFor="male">Male</label></li>
                        <li><input type="checkbox" id="female" /> <label htmlFor="female">Female</label></li>
                        <li><input type="checkbox" id="unisex" /> <label htmlFor="unisex">Unisex</label></li>
                    </ul>
                </div>
                {/* Categories Filter */}
                <div className="filter-group">
                    <h4>Categories</h4>
                    <ul>
                        {
                            subcategories.map(sun =>
                                <li><input type="checkbox" id="shoes" /> <label htmlFor="shoes">{sun.name}</label></li>
                            )
                        }

                    </ul>
                </div>
                {/* Color Filter */}
                <div className="filter-group">
                    <h4>Color</h4>
                    <ul>
                        <li><input type="checkbox" id="red" /> <label htmlFor="red">Red</label></li>
                        <li><input type="checkbox" id="blue" /> <label htmlFor="blue">Blue</label></li>
                        <li><input type="checkbox" id="green" /> <label htmlFor="green">Green</label></li>
                        <li><input type="checkbox" id="black" /> <label htmlFor="black">Black</label></li>
                    </ul>
                </div>

                {/* Offers Filter */}
                <div className="filter-group">
                    <h4>Offers</h4>
                    <ul>
                        <li><input type="checkbox" id="discount" /> <label htmlFor="discount">Discount</label></li>
                        <li><input type="checkbox" id="free-shipping" /> <label htmlFor="free-shipping">Free Shipping</label></li>
                    </ul>
                </div>

                {/* Price Range Filter */}
                <div className="filter-group">
                    <h4>Price Range</h4>
                    <input
                        type="range"
                        min="0"
                        max="1000"
                        step="10"
                        value={priceRange}
                        onChange={handlePriceChange}
                        className="price-range"
                    />
                    <p>Price: ${priceRange[0]} - ${priceRange[1]}</p>
                </div>

                {/* Brands Filter */}
                <div className="filter-group">
                    <h4>Brands</h4>
                    <ul>
                        <li><input type="checkbox" id="nike" /> <label htmlFor="nike">Nike</label></li>
                        <li><input type="checkbox" id="adidas" /> <label htmlFor="adidas">Adidas</label></li>
                        <li><input type="checkbox" id="puma" /> <label htmlFor="puma">Puma</label></li>
                    </ul>
                </div>

                {/* Occasions Filter */}
                <div className="filter-group">
                    <h4>Occasions</h4>
                    <ul>
                        <li><input type="checkbox" id="casual" /> <label htmlFor="casual">Casual</label></li>
                        <li><input type="checkbox" id="formal" /> <label htmlFor="formal">Formal</label></li>
                        <li><input type="checkbox" id="sport" /> <label htmlFor="sport">Sport</label></li>
                    </ul>
                </div>


            </div>

            {/* Right-side Product List */}
            <div className="products-list-container">
                {products.map(product => (
                    <div
                        key={product.id}
                        onClick={() => handleCardClick(product.id)}
                    >
                        <ProductCard product={product} />
                    </div>
                ))}
            </div>

            {/* Loading Indicator */}
            {isFetching && <p className="loading">Loading more products...</p>}
        </div>
    );
};

export default ProductsSection;
