import React, { useEffect, useState } from 'react';
import './Referral.css';
import { getCredits } from '../../util/AuthApiService';

const Referral = () => {
  const [referralCode] = useState('JOHN2024'); // Example code
  const [earnings, setEarnings] = useState(null); // Changed from [] to null
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('users')));

  useEffect(() => {
    if (user && user.email) {
      getCredits(user.email).then((response) => {
        console.log(response.data);
        setEarnings(response.data);
      });
    }
  }, [user]);

  // Ensure earnings is not null before accessing properties
  const totalEarnings = earnings?.creditHistoryDto
    ?.filter((earning) => earning.status.toLowerCase() === 'credited')
    ?.reduce((total, earning) => total + earning.amount, 0) || 0;

  const copyReferralCode = () => {
    navigator.clipboard.writeText(referralCode);
    alert("Referral code copied!"); // Added feedback
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="referral-container">
      {/* Referral Code Section */}
      <section className="referral-code-section">
        <div className="referral-code-content">
          <div className="referral-info">
            <h1>Your Referral Code</h1>
            <p className="referral-description">
              Share this code with friends and earn ₹100 when they make their first purchase
            </p>
          </div>
          <div className="code-display">
            <div className="code-box">
              <span className="code">{referralCode}</span>
              <button onClick={copyReferralCode} className="copy-button">
                Copy Code
              </button>
            </div>
            <div className="share-buttons">
              <button className="share-button whatsapp">Share on WhatsApp</button>
              <button className="share-button facebook">Share on Facebook</button>
            </div>
          </div>
        </div>
      </section>

      {/* Earnings Section */}
      <section className="earnings-section">
        <div className="earnings-header">
          <div className="total-earnings">
            <h2>Total Earnings</h2>
            <span className="amount">₹{totalEarnings}</span>
          </div>
          <div className="earnings-stats">
            <div className="stat-box">
            <span className="stat-value">
                {user.totalNumberReferrals || 0}
              </span>
              <span className="stat-label">Total Referrals</span>
            </div>
            <div className="stat-box">
              <span className="stat-value">
                {earnings?.creditHistoryDto?.filter((e) => e.status.toLowerCase() === 'credited').length || 0}
              </span>
              <span className="stat-label">Successful Referrals</span>
            </div>
          </div>
        </div>

        {/* Earnings History */}
        <div className="earnings-history">
          <h3>Earnings History</h3>
          <div className="history-list">
            {earnings?.creditHistoryDto?.map((earning) => (
              <div key={earning.dateTime} className="history-item">
                <div className="history-info">
                  <span className="referred-user">{earning.refferedBy}</span>
                  <span className="earning-date">{formatDate(earning.dateTime)}</span>
                </div>
                <div className="history-amount">
                  <span className={`status ${earning.status.toLowerCase()}`}>
                    {earning.status.charAt(0).toUpperCase() + earning.status.slice(1)}
                  </span>
                  <span className="amount">₹{earning.amount}</span>
                </div>
              </div>
            )) || <p>No earnings yet.</p>}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Referral;
