import React, { useState, useEffect, useContext } from 'react';
import { Search, Heart, ShoppingCart, User, Globe } from 'lucide-react';
import './Header.css';
import { getMenu } from '../../util/ApiService';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';

const languageOptions = [
  { label: 'English', code: 'en' },
  { label: 'Spanish', code: 'es' },
  { label: 'Chinese', code: 'cn' },
  { label: 'Japanese', code: 'jp' },
  { label: 'Taiwan', code: 'tw' },
  { label: 'French', code: 'fr' },
];

const Header = ({ setSubcategories, setIsClicked }) => {
  const { currentLang, setCurrentLang, t } = useContext(LanguageContext);
  const [activeCategory, setActiveCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('users')));
  const [selectedMenu, setSelectedMenu] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories(currentLang);
  }, [currentLang]);

  const fetchCategories = (language) => {
    getMenu(language)
      .then((response) => {
        const fetchedCategories = response.data.categories || [];
        setCategories(fetchedCategories);
        setSelectedMenu(0);
        updateSubcategories(fetchedCategories[0]);
      })
      .catch((err) => {
        console.error("Failed to fetch Categories", err);
      });
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setCurrentLang(newLanguage);
    localStorage.setItem('selectedLanguage', newLanguage);
    window.dispatchEvent(new Event('languageChanged'));
    fetchCategories(newLanguage);
  };

  const handleMenuClick = (index, category) => {
    setSelectedMenu(index);
    setActiveCategory(category.name === 'CRAFT' ? 'CRAFT' : null);
    updateSubcategories(category);
    setIsClicked(true);
    navigate('/product');
  };

  const updateSubcategories = (category) => {
    setSubcategories(category?.children || []);
  };

  const navigationHandlers = {
    cart: () => navigate('cart'),
    profile: () => navigate(user ? 'profile' : 'login'),
    wishlist: () => navigate('wishlist')
  };

  return (
    <div className="header-container">
      <div className="announcement-bar">
        <div className="announcement-content">
          <div className="flex-1" />
          <div className="text-center flex-1">
            {t('header.announcement')}
            <span className="shop-now">{t('header.shopNow')}</span>
          </div>
          <div className="flex-1 flex justify-end">
            <select className="language-selector" value={currentLang} onChange={handleLanguageChange}>
              {languageOptions.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="main-header">
        <div className="header-content">
          <div className="logo">
            <img src='logo.jpg' alt="Logo" className="logo-image" />
          </div>

          <nav className="main-navigation">
            {categories.map((item, index) => (
              <button
                key={item.name}
                className={`nav-item ${index === selectedMenu ? 'active' : ''}`}
                onClick={() => handleMenuClick(index, item)}
              >
                {item.name}
              </button>
            ))}
          </nav>

          <div className="search-and-icons">
            <div className="search-box">
              <input type="text" placeholder={t('header.searchPlaceholder')} className="search-input" />
              <Search className="search-icon" size={20} />
            </div>
            <button className="icon-btn" onClick={navigationHandlers.wishlist}><Heart size={24} /></button>
            <button className="icon-btn" onClick={navigationHandlers.cart}><ShoppingCart size={24} /></button>
            <button className="icon-btn" onClick={navigationHandlers.profile}><User size={24} /></button>
            <button className="icon-btn"><Globe size={24} /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;