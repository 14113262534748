import React, { useState, useEffect,useContext } from 'react';
import ProductCard from  './widget/ProductCard';
import './Flash/FlashSales.css';
import { useNavigate } from 'react-router-dom';
import { getProducts } from '../util/ApiService';
import { LanguageContext } from '../context/LanguageContext';

const ExploreOurProducts = () => {
    const navigate = useNavigate(); 
    const [products,setProducts]=useState([]);
    const { t } = useContext(LanguageContext);
 const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');

  const params = {
    offset: 0,
    pagesize: 8,
    languageCode: selectedLanguage
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      const newLanguage = localStorage.getItem('selectedLanguage');
      setSelectedLanguage(newLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);
    return () => window.removeEventListener('languageChanged', handleLanguageChange);
  }, []);

  useEffect(() => {
    getProducts(params)
      .then(response => {
        setProducts(response.data.content);
      })
      .catch(error => {
        console.error(error);
      });
  }, [selectedLanguage]);

    const handleClick = () => {
        navigate('/product/1');

    };
  return (
    <div className="flash-sales">
      <div className="header">
        <div className="today-label-wrapper">
          <span className="separator">|</span>
          <p className="today-label">{t('labels.ourProducts')}</p>
        </div>
        <div className="flash-sales-info">
          <h3>{t('labels.exploreProducts')}</h3>
        </div>
      </div>
      <div className="product-list">
        {products.map(product => <ProductCard key={product.id} product={product} />)}
      </div>
      <button className="view-all"  onClick={handleClick}>{t('labels.viewAll')}</button>
    </div>
  );
};

export default ExploreOurProducts;
