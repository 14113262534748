import React, { useState, useEffect } from 'react';
import { getProduct } from '../../util/ApiService';
import './ProductDetails.css';
import { Truck, RotateCcw } from 'lucide-react';
import { useParams } from "react-router-dom";

const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');
  let { id } = useParams();
  const addToCart = () => {
    setCart([...cart, { ...currentVariant, quantity }]);
    alert("Product added to cart!");
  };

  const addToWishlist = () => {
    setWishlist([...wishlist, { ...currentVariant, quantity }]);
    alert("Product added to wishlist!");
  };
  useEffect(() => {
    const handleLanguageChange = () => {
      const newLanguage = localStorage.getItem('selectedLanguage');
      setSelectedLanguage(newLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);
    return () => window.removeEventListener('languageChanged', handleLanguageChange);
  }, []);

  useEffect(() => {
  
    const fetchProduct = async () => {
      try {
        const data = await getProduct(id, selectedLanguage);
        setProduct(data.data);
        if (data.data?.variants?.length > 0) {
          setSelectedColor(data.data.variants[0].color);
          setSelectedSize(data.data.variants[0].size);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };
    if (id) {
      fetchProduct();
    }
  }, [id, selectedLanguage]);

  if (!product) return <p className="loading-state">Loading...</p>;

  // Get available sizes based on selected color
  const availableSizes = [...new Set(product.variants
    .filter(v => v.color === selectedColor)
    .map(v => v.size))];

  // Get available colors based on selected size
  const availableColors = [...new Set(product.variants
    .filter(v => v.size === selectedSize)
    .map(v => v.color))];

  // Get the current selected variant
  const currentVariant = product.variants.find(
    v => v.color === selectedColor && v.size === selectedSize
  ) || product.variants[0];

  // Calculate discount amount and percentage
  const calculateDiscount = (variant) => {
    const discountAmount = variant.actualPrice - variant.sellingPrice;
    const discountPercentage = Math.round((discountAmount / variant.actualPrice) * 100);
    return {
      amount: discountAmount,
      percentage: discountPercentage
    };
  };

  return (
   
    <div className="product-container">
      <div className="product-grid">
        {/* Image Section */}
        <div className="image-gallery">
          <div className="thumbnails">
            {product.images?.map((img, index) => (
              <button
                key={index}
                onClick={() => setSelectedImage(index)}
                className={`thumbnail-btn ${selectedImage === index ? 'active' : ''}`}
              >
                <img src={img} alt={`View ${index + 1}`} className="thumbnail-img" />
              </button>
            ))}
          </div>
          <div className="main-image">
            <img
              src={product.images?.[selectedImage] || '/placeholder.jpg'}
              alt={product.name}
              className="product-img"
            />
          </div>
        </div>

        {/* Product Details */}
        <div className="product-info">
          <h1 className="product-title-d">{product.name}</h1>
          <p className="product-description">{product.description}</p>

          {/* Price Display */}
          {currentVariant && (
            <div className="price-container">
              <span className="selling-price">${currentVariant.sellinglPrice - (currentVariant.sellinglPrice * product.discountPercentage / 100).toFixed(2)}</span>
              <span className="original-price">₹{currentVariant.sellinglPrice}</span>
              <span className="discount-d">
                {product.discountPercentage}% off
              </span>
              <span className="discount-amount">
                You save: ${(currentVariant.sellinglPrice * product.discountPercentage / 100).toFixed(2)}
              </span>
            </div>

          )}


          {/* Rest of the component remains the same */}
          {/* Color Options */}
          {product.variants.length > 1 && (
            <div className="variant-section">
              <h3 className="variant-title">Color:</h3>
              <div className="color-options">
                {availableColors.map((color) => (
                  <button
                    key={color}
                    style={{ backgroundColor: color }}
                    onClick={() => setSelectedColor(color)}
                    className={`color-btn ${selectedColor === color ? 'active' : ''}`}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Size Options */}
          {product.variants.length > 1 && (
            <div className="variant-section">
              <h3 className="variant-title">Size:</h3>
              <div className="size-options">
                {availableSizes.map((size) => (
                  <button
                    key={size}
                    onClick={() => setSelectedSize(size)}
                    className={`size-btn ${selectedSize === size ? 'active' : ''}`}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="purchase-actions">
            <button className="add-to-cart-btn" onClick={addToCart}>
              Add to Cart
            </button>
            <button className="add-to-wishlist-btn" onClick={addToWishlist}>
              Add to Wishlist
            </button>
          </div>

          <div className="delivery-info">
            <div className="delivery-item">
              <Truck className="delivery-icon" />
              <div className="delivery-text">
                <div className="delivery-title">Free Delivery</div>
                <div className="delivery-subtitle">Enter your postal code for Delivery Availability</div>
              </div>
            </div>
            <div className="delivery-item">
              <RotateCcw className="delivery-icon" />
              <div className="delivery-text">
                <div className="delivery-title">Return Delivery</div>
                <div className="delivery-subtitle">
                  Free 30 Days Delivery Returns. <button className="details-link">Details</button>
                </div>
              </div>
            </div>
          </div>

          {product.specifications?.length > 0 && (
            <div className="specifications">
              <h3>Specifications</h3>
              {product.specifications.map((spec, index) => (
                <div key={index} className="spec-category">
                  <h4>{spec.additionalInfo}</h4>
                  <div className="spec-grid">
                    {Object.entries(spec.additionalSpecs || {}).map(([key, value]) => (
                      <div key={key} className="spec-item">
                        <span className="spec-key">{key}</span>
                        <span className="spec-value">{value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
    
  );
};

export default ProductDetails;