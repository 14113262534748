import React, { useState, useEffect ,useContext} from 'react';
import './FlashSales.css';
import ProductCard from  '../widget/ProductCard';
import { getProducts } from '../../util/ApiService';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';


const Timer = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date("2025-01-31") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [time, setTime] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="timer">
      {Object.values(time).map((t, i) => (
        <div key={i} className="time-box">
          <span>{String(t).padStart(2, '0')}</span>
        </div>
      ))}
    </div>
  );
};



const FlashSales = (setIsClicked) => {
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');
  const { t } = useContext(LanguageContext);
  const [products,setProducts]=useState([])
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/product/${id}`);
  };
  const params = {
    offset: 0,
    pagesize: 8,
    languageCode: selectedLanguage
  };
  
     useEffect(() => {
          const handleLanguageChange = () => {
              const newLanguage = localStorage.getItem('selectedLanguage');
              setSelectedLanguage(newLanguage);
          };
      
          window.addEventListener('languageChanged', handleLanguageChange);
          return () => window.removeEventListener('languageChanged', handleLanguageChange);
      }, []);

  useEffect(() => {
    getProducts(params)
    .then(response => {
      setProducts(response.data.content); 
    })
    .catch(error => {
      console.error(error); 
    });
  }, [selectedLanguage]);
 
  return (
    <div className="flash-sales">
      <div className="header">
        <div className="today-label-wrapper">
          <span className="separator">|</span>
          <p className="today-label">{t('labels.today')}</p>
        </div>
        <div className="flash-sales-info">
          <h3>{t('labels.flashSales')}</h3>
          <Timer />
        </div>
      </div>
      <div className="product-list">
      {products.map(product => (
        <div
          key={product.id}
          onClick={() => handleCardClick(product.id)}
        >
          <ProductCard product={product} />
        </div>
      ))}    
      </div>
      <button className="view-all">{t('labels.viewAll')}</button>
    </div>
  );
};

export default FlashSales;
