import React, { useEffect, useState ,useContext } from 'react';
import './Deals.css';
import { getDeals } from '../../util/ApiService';
import { LanguageContext } from '../../context/LanguageContext';
const Deals = () => {
  const { t } = useContext(LanguageContext);
  const [deal, setDeal] = useState([]);
  useEffect(() => {
    getDeals().then((resposne) => {
      setDeal(resposne.data);
    })
  }, [])

  return (
    <div className="collection-container">
      <div className="header">
        <div className="today-label-wrapper">
          <span className="separator">|</span>
          <p className="today-label">{t('deals.seasonLabel')}</p>
        </div>
        <div className="flash-sales-info">
          <h3>{t('deals.title')}</h3>
        </div>
      </div>
      <div className="card-grid">
        {deal.map((item, index) => (
          <div className="card" key={index}>
            <div className="card-image">
              <img src={item.dealUrl} alt={item.offerValue} />
            </div>
            <div className="card-content">
              <button className="view-button">{t('deals.viewDeals')}</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Deals;