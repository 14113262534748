import React, { useState, useEffect,useContext } from "react";
import { LanguageContext } from '../../context/LanguageContext';

const ProfileContent = () => {
  const storedUser = JSON.parse(localStorage.getItem("users")) || {};
  const [username, setUsername] = useState(storedUser.username || "");
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [timer, setTimer] = useState(60);
  const [passwords, setPasswords] = useState({ newPassword: "", confirmPassword: "" });
  const { t } = useContext(LanguageContext);

  useEffect(() => {
    let interval;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isOtpSent, timer]);

  const handlePasswordUpdate = () => {
    setIsUpdatingPassword(true);
    setIsOtpSent(true);
    setTimer(60);
  };

  const handleResendOtp = () => {
    setTimer(60);
    setOtp("");
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    if (passwords.newPassword === passwords.confirmPassword) {
      alert("Password updated successfully!");
      setIsUpdatingPassword(false);
      setIsOtpSent(false);
      setOtp("");
      setPasswords({ newPassword: "", confirmPassword: "" });
    } else {
      alert("Passwords do not match.");
    }
  };

  return (
    <div className="profile-content">
      <h2 className="content-title">Edit Your Profile</h2>
      <form className="profile-form">
        <div className="form-grid">
          <div className="form-group">
            <label>{t('labels.email')}</label>
            <input type="email" value={storedUser.email} disabled />
          </div>
          <div className="form-group">
            <label>{t('labels.subscriptionType')}</label>
            <input type="text" value={storedUser.subscriptionType} disabled />
          </div>
          <div className="form-group">
            <label>{t('labels.referralCode')}</label>
            <input type="text" value={storedUser.referral} disabled />
          </div>
          <div className="form-group">
            <label>{t('labels.referredBy')}</label>
            <input type="text" value={storedUser.referredBy || "N/A"} disabled />
          </div>
          <div className="form-group">
            <label>{t('labels.username')}</label>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
        </div>

        {/* Update Password Section */}
        {!isUpdatingPassword ? (
          <div className="form-group">
            <button type="button" className="btn btn-update-password" onClick={handlePasswordUpdate}>
            {t('labels.updatePassword')}
            </button>
          </div>
        ) : (
          <div className="password-update-section">
            <h3 className="section-title">{t('labels.enterOtp')}</h3>
            <div className="form-group">
              <input type="text" maxLength="6" placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
            </div>
            <p className="otp-timer">{t('labels.resendOtpIn')} {timer}s</p>
            {timer === 0 && (
              <button type="button" className="btn btn-resend" onClick={handleResendOtp}>
                {t('labels.resendOtp')} 
              </button>
            )}

            {otp.length === 6 && (
              <>
                <h3 className="section-title">{t('labels.setNewPassword')} </h3>
                <div className="form-group">
                  <input type="password" placeholder="New Password" value={passwords.newPassword} onChange={(e) => setPasswords({ ...passwords, newPassword: e.target.value })} />
                </div>
                <div className="form-group">
                  <input type="password" placeholder="Confirm New Password" value={passwords.confirmPassword} onChange={(e) => setPasswords({ ...passwords, confirmPassword: e.target.value })} />
                </div>
                <button type="submit" className="btn btn-save" onClick={handleSubmitPassword}>
                {t('labels.savePassword')}
                </button>
              </>
            )}
          </div>
        )}

        <div className="form-actions">
          <button type="button" className="btn btn-cancel">{t('labels.cancel')}</button>
          <button type="submit" className="btn btn-save">{t('labels.saveChanges')}</button>
        </div>
      </form>
    </div>
  );
};

export default ProfileContent;
