import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import "./checkoutForm.css";

const stripePromise = loadStripe("pk_test_2ejIQF72yHDN0RfsQhJ6Lc8j"); // Replace with your Stripe public key

const address = {
  id: 21,
  name: "John Doe",
  flatNo: "123 Main St",
  addrssLine1: "New York",
  addrsesLine2: "NY",
  zipocode: "10001",
  phone: "(555) 123-4567",
  country: "USA",
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: window.location.origin },
      redirect: "if_required",
    });

    if (error) {
      console.error(error.message);
      navigate("/failure");
    } else if (paymentIntent?.status === "succeeded") {
      navigate("/success");
    } else {
      navigate("/failure");
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <h2>Complete Your Payment</h2>
      <p className="secure-text">🔒 Secure & Reliable Payment</p>
      <PaymentElement />
      <button className="button-payment" type="submit" disabled={!stripe || loading}>
        {loading ? "Processing..." : "Pay $1500"}
      </button>
      {error && <div className="error">{error}</div>}
    </form>
  );
};

const PaymentPage = () => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch("https://api.mememall.tv/api/payment/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount: 10000, currency: "usd" }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((err) => console.error("Error fetching clientSecret:", err));
  }, []);

  const options = { clientSecret };

  return (
    <div className="checkout-container">
      <div className="checkout-breadcrumb">
        <span>Accounts</span> / <span>My Account</span> / <span>Product</span> /
        <span>View Cart</span> / <span>CheckOut</span> /
        <span className="active">Payment</span>
      </div>

      <div className="checkout-content">
        <div className="billing-form">
          <h2>Billing Details</h2>
          <div className="saved-addresses">
            <div className="address-card">
              <h3>Delivery Address</h3>
              <p><strong>{address.name}</strong></p>
              <p>{address.flatNo}</p>
              <p>{address.addrssLine1}, {address.addrsesLine2}</p>
              <p>{address.zipocode}</p>
              <p>{address.phone}</p>
            </div>
          </div>
        </div>

        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default PaymentPage;
